import clientAxios from '@/libs/api-client'

export const getWithdrawalMethods = () =>
  clientAxios({
    method: 'GET',
    url: 'withdrawal-method',
    params: {
      status: 1,
    },
  }).then((res) => res.data ?? [])
