import { lazy } from 'react'
import paths from './utils/constants/paths'
const Login = lazy(() => import('@/views/pages/Login'))
const Homepage = lazy(() => import('@/views/pages/Homepage'))
const AdminList = lazy(() => import('@/views/pages/Admin/List'))
const AdminForm = lazy(() => import('@/views/pages/Admin/Form'))
const BusinessCalculator = lazy(
  () => import('@/views/pages/BusinessCalculator')
)
const Reports = lazy(() => import('@/views/pages/Report'))
const Settings = lazy(() => import('@/views/pages/Settings'))
const PendingMemberList = lazy(() => import('@/views/pages/PendingMember/List'))
const MemberList = lazy(() => import('@/views/pages/Member/List'))
const TransactionList = lazy(() => import('@/views/pages/Transaction/List'))
const WithdrawalList = lazy(() => import('@/views/pages/Withdrawal/List'))
const DetailMember = lazy(() => import('@/views/pages/Member/DetailMember'))

const routes: {
  title: string
  container: React.LazyExoticComponent<() => JSX.Element>
  path: string
  useLayout: boolean
}[] = [
  {
    title: 'Login',
    container: Login,
    path: paths.login,
    useLayout: false,
  },
  {
    title: 'Homepage',
    container: Homepage,
    path: paths.dashboard,
    useLayout: true,
  },
  {
    title: 'Manage Admin',
    container: AdminList,
    path: paths.admin,
    useLayout: true,
  },
  {
    title: 'Tambah Admin',
    container: AdminForm,
    path: paths.adminCreate,
    useLayout: true,
  },
  {
    title: 'Ubah Admin',
    container: AdminForm,
    path: `${paths.adminEdit}:id`,
    useLayout: true,
  },
  {
    title: 'Bisnis Kalkulator',
    container: BusinessCalculator,
    path: paths.calculator,
    useLayout: true,
  },
  {
    title: 'Laporan',
    container: Reports,
    path: paths.report,
    useLayout: true,
  },
  {
    title: 'Pengaturan',
    container: Settings,
    path: paths.setting,
    useLayout: true,
  },
  {
    title: 'Pending Member List',
    container: PendingMemberList,
    path: paths.pendingMember,
    useLayout: true,
  },
  {
    title: 'Member List',
    container: MemberList,
    path: paths.member,
    useLayout: true,
  },
  {
    title: 'Detail Member',
    container: DetailMember,
    path: `${paths.memberDetail}:id`,
    useLayout: true,
  },
  {
    title: 'Transaction List',
    container: TransactionList,
    path: paths.transaction,
    useLayout: true,
  },
  {
    title: 'Withdrawal Member',
    container: WithdrawalList,
    path: paths.withdrawalMember,
    useLayout: true,
  },
]

export default routes
