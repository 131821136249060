interface Props {
  size?: number
  color?: string
}

const IconSearch = ({ size = 24, color = '#202020' }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.7029 28.7263C32.7113 26.3128 33.7155 23.2961 33.7155 19.8771C33.7155 12.2346 27.4895 6 19.8577 6C12.2259 6 6 12.2346 6 19.8771C6 27.5196 12.2259 33.7542 19.8577 33.7542C23.0711 33.7542 26.0837 32.5475 28.4937 30.7374L39.5397 41.5978C39.7406 41.7989 40.1423 42 40.5439 42C40.9456 42 41.3473 41.7989 41.5481 41.5978C42.1506 40.9944 42.1506 39.9888 41.5481 39.3855L30.7029 28.7263ZM9.01255 19.8771C9.01255 13.8436 13.8326 9.01676 19.8577 9.01676C25.8828 9.01676 30.7029 13.8436 30.7029 19.8771C30.7029 25.9106 25.8828 30.7374 19.8577 30.7374C13.8326 30.7374 9.01255 25.9106 9.01255 19.8771Z"
        fill={color}
      />
    </svg>
  )
}

export default IconSearch
