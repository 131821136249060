import { styled } from '@/styled'

export const SidebarWrapper = styled('div', {
  position: 'fixed',
  left: 0,
  top: 0,
  height: '100vh',
  width: '230px',
  overflowY: 'auto',
  backgroundColor: '$white',
  paddingTop: '65px',
  zIndex: 999,
  '@bp1': {
    width: '1024px',
  },
})

export const SidebarItems = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '16px',
})

export const SidebarItem = styled('div', {
  cursor: 'pointer',
  padding: '12px',
  borderRadius: '6px',
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '$smokeLine2',
  },
  '& svg': {
    fontSize: '20px',
    color: '$smokeText',
  },
  variants: {
    active: {
      true: {
        backgroundColor: '$primaryLight !important',
        color: '$white !important',
        '& svg': {
          color: '$white !important',
        },
      },
    },
  },
})
