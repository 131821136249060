import { styled } from '@/styled'

export const MainWrapper = styled('div', {
  backgroundColor: '$smokeLight',
  paddingLeft: '230px',
  paddingTop: '65px',
  '@bp1': {
    paddingLeft: '0px',
    width: '1024px',
  },
})
export const MainContent = styled('div', {
  height: 'calc(100vh - 65px)',
  width: 'calc(100vw - 230px)',
  overflow: 'auto',
  padding: '24px',
  '@bp1': {
    width: '1024px',
  },
})
