const paths = {
  dashboard: '/',
  member: '/member',
  memberDetail: '/member/detail/',
  pendingMember: '/pending-member',
  withdrawalMember: '/withdrawal-member',
  transaction: '/transaction',
  transactionReport: '/report/transaction',
  admin: '/admin',
  adminCreate: '/admin/create',
  adminEdit: '/admin/edit/',
  calculator: '/calculator',
  report: '/report',
  setting: '/setting',
  login: '/login',
}

export default paths
