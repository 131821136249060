const zIndex = {
  modalAlert: 1400,
  modal: 1300,
  navbar: 1200,
  mission: 20,
  sidebar: 15,
  bulkActionButton: 10,
  dropdown: 100,
  // must lower than bulkActionButton
  tableFixedColumn: 9,
  zDropdown: 9999,
  pagination: 10,
  tableRow: 1000,
}

export default zIndex
