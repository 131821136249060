export const POOKPOW_WALLET_USERNAME = 'admin@pookpow.id'
export const BANK_TRANSFER_KEY = 'bank_transfer'
export const MANUAL_TRANSFER_KEY = 'manual_transfer'
export const AMOUNT_MINIMUM = 10000
export const AMOUNT_MINIMUM_MITRA_BALANCE = 25000
export const MONTHS = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
]
export const YEARS = ['2023', '2024', '2025', '2026', '2027', '2028']
export const DEFAULT_EMAIL_EXPORT_EXCEL = 'sadamaditya470@gmail.com'
export const POOKPOW_SQUAD = 'squad'
export enum USER_TYPE {
  MEMBER = 1,
  MITRA = 2,
}

export const SUPERADMIN_LIST = [
  'superadmin',
  'administrator_fikly',
  'administrator_sadam',
  'admin_ilham',
]
