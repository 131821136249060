interface Props {
  size?: number
  color?: string
}

const IconFilter = ({ size = 24, color = '#202020' }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none">
      <path
        d="M7.60869 13.0306H27.2605C27.9381 15.3176 30.0557 17.0118 32.5122 17.0118C34.9687 17.0118 37.0863 15.3176 37.764 13.0306H40.4746C41.3216 13.0306 41.9993 12.3529 41.9993 11.5059C41.9993 10.6588 41.3216 9.98118 40.4746 9.98118H37.764C37.171 7.69412 35.0534 6 32.5122 6C29.971 6 27.9381 7.69412 27.2605 9.98118H7.60869C6.76163 9.98118 6.08398 10.6588 6.08398 11.5059C6.08398 12.3529 6.76163 13.0306 7.60869 13.0306ZM32.5122 8.96471C33.8675 8.96471 35.0534 10.0659 35.0534 11.5059C35.0534 12.9459 33.9522 14.0471 32.5122 14.0471C31.0722 14.0471 29.971 12.9459 29.971 11.5059C29.971 10.0659 31.1569 8.96471 32.5122 8.96471Z"
        fill={color}
      />
      <path
        d="M40.5593 34.0376H37.8487C37.171 31.7505 35.0534 30.0564 32.5969 30.0564C30.1405 30.0564 28.0228 31.7505 27.3452 34.0376H7.60869C6.76163 34.0376 6.08398 34.7152 6.08398 35.5623C6.08398 36.4093 6.76163 37.087 7.60869 37.087H27.2605C27.9381 39.374 30.0557 41.0682 32.5122 41.0682C34.9687 41.0682 37.0863 39.374 37.764 37.087H40.4746C41.3216 37.087 41.9993 36.4093 41.9993 35.5623C41.9993 34.7152 41.3216 34.0376 40.5593 34.0376ZM32.5122 38.0187C31.1569 38.0187 29.971 36.9176 29.971 35.4776C29.971 34.0376 31.0722 32.9364 32.5122 32.9364C33.9522 32.9364 35.0534 34.0376 35.0534 35.4776C35.0534 36.9176 33.8675 38.0187 32.5122 38.0187Z"
        fill={color}
      />
      <path
        d="M40.56 22.0094H20.7388C20.0612 19.7223 17.9435 18.0282 15.4871 18.0282C13.0306 18.0282 10.9129 19.7223 10.2353 22.0094H7.52471C6.67765 22.0094 6 22.687 6 23.5341C6 24.3811 6.67765 25.0588 7.52471 25.0588H10.2353C10.9129 27.3458 13.0306 29.04 15.4871 29.04C17.9435 29.04 20.0612 27.3458 20.7388 25.0588H40.4753C41.3224 25.0588 42 24.3811 42 23.5341C42 22.687 41.3224 22.0094 40.56 22.0094ZM15.4871 25.9906C14.1318 25.9906 12.9459 24.8894 12.9459 23.4494C12.9459 22.0094 14.0471 20.9082 15.4871 20.9082C16.9271 20.9082 18.0282 22.0094 18.0282 23.4494C18.0282 24.8894 16.9271 25.9906 15.4871 25.9906Z"
        fill={color}
      />
    </svg>
  )
}

export default IconFilter
