import colors from '@/styled/colors'
import { IconProps } from './types'

const IconVisibilityOn = ({ width, height, color }: IconProps) => (
  <svg
    width={width || 20}
    height={height || 13}
    viewBox="0 0 20 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={`M2.82812 6.19234L4.43903 4.58143C7.56323 1.45723 12.6285 1.45723 
      15.7527 4.58143L17.3637 6.19234L15.7527 7.80324C12.6285 10.9274 7.56323 
      10.9274 4.43903 7.80324L2.82812 6.19234ZM1.41391 7.60655C0.632863 6.8255 
      0.632863 5.55917 1.41391 4.77812L3.02482 3.16721C6.93006 -0.738029 13.2617 
      -0.73803 17.167 3.16721L18.7779 4.77812C19.5589 5.55917 19.5589 6.8255 18.7779 
      7.60655L17.167 9.21746C13.2617 13.1227 6.93006 13.1227 3.02482 9.21746L1.41391 
      7.60655ZM9.9997 7.99995C11.1043 7.99995 11.9997 7.10452 11.9997 5.99995C11.9997 
      4.89538 11.1043 3.99995 9.9997 3.99995C8.89513 3.99995 7.9997 4.89538 7.9997 
      5.99995C7.9997 7.10452 8.89513 7.99995 9.9997 7.99995Z`}
      fill={color || colors.smoke}
    />
  </svg>
)

export default IconVisibilityOn
