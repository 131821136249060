import { IconType } from 'react-icons'
import {
  FiDollarSign,
  FiHome,
  FiSettings,
  FiUser,
  FiUsers,
  // FiUserCheck,
  FiDownloadCloud,
  FiBriefcase,
  FiArchive,
} from 'react-icons/fi'
import paths from './paths'

interface menuProps {
  label: string
  key: string
  path: string
  icon: IconType
}

const menus: menuProps[] = [
  {
    label: 'Beranda',
    key: 'dashboard',
    path: paths.dashboard,
    icon: FiHome,
  },
  {
    label: 'Member',
    key: 'member',
    path: paths.member,
    icon: FiUsers,
  },
  // {
  //   label: 'Pending Member',
  //   key: 'pendingMember',
  //   path: paths.pendingMember,
  //   icon: FiUserCheck,
  // },
  {
    label: 'Pencairan Dana Member',
    key: 'withdrawalMember',
    path: paths.withdrawalMember,
    icon: FiDownloadCloud,
  },
  {
    label: 'Transaksi',
    key: 'transaction',
    path: paths.transaction,
    icon: FiDollarSign,
  },
  {
    label: 'Admin',
    key: 'admin',
    path: paths.admin,
    icon: FiUser,
  },
  {
    label: 'Bisnis Kalkulator',
    key: 'calculator',
    path: paths.calculator,
    icon: FiBriefcase,
  },
  {
    label: 'Laporan',
    key: 'report',
    path: paths.report,
    icon: FiArchive,
  },
  {
    label: 'Pengaturan',
    key: 'setting',
    path: paths.setting,
    icon: FiSettings,
  },
]

export default menus
