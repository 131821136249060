import { createStitches } from '@stitches/react'

import colors from './colors'
import fontSize from './fontSize'
import fontWeight from './fontWeight'
import utils from './utils'
import media from './media'

export const { styled, globalCss, css, theme, getCssText, keyframes } =
  createStitches({
    theme: {
      colors,
      fontSizes: fontSize,
      fontWeights: fontWeight,
    },
    utils,
    media,
  })

export type ColorKeys = keyof typeof theme.colors
