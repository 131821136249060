import { FC } from 'react'

interface IconProps {
  size?: number
  color?: string
}

const IconDownload: FC<IconProps> = ({ size = 24, color = '#333939' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.01617 12.3859C9.21347 12.3901 9.41212 12.3169 9.56268 12.1664L12.7447 8.98439C13.0376 8.69149 13.0376 8.21662 12.7447 7.92373C12.4518 7.63083 11.9769 7.63083 11.684 7.92373L9.78236 9.82538V3C9.78236 2.58579 9.44657 2.25 9.03235 2.25C8.61814 2.25 8.28235 2.58579 8.28235 3L8.28236 9.85773L6.34835 7.92373C6.05546 7.63083 5.58058 7.63083 5.28769 7.92373C4.9948 8.21662 4.9948 8.69149 5.28769 8.98439L8.46967 12.1664C8.62023 12.3169 8.81888 12.3901 9.01617 12.3859Z"
        fill={color}
      />
      <path
        d="M3.75 12C3.75 11.5858 3.41421 11.25 3 11.25C2.58579 11.25 2.25 11.5858 2.25 12V14.25C2.25 15.0784 2.92157 15.75 3.75 15.75H14.25C15.0784 15.75 15.75 15.0784 15.75 14.25V12C15.75 11.5858 15.4142 11.25 15 11.25C14.5858 11.25 14.25 11.5858 14.25 12V14.25H3.75V12Z"
        fill={color}
      />
    </svg>
  )
}

export default IconDownload
