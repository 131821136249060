import Text from '@/components/Text'
import { logo } from '@/utils/images'
import {
  HeaderAccount,
  HeaderAccountIcon,
  HeaderLogo,
  HeaderWrapper,
} from './styles'
import { HiUsers } from 'react-icons/hi'
import { Dispatch, SetStateAction } from 'react'
import { isMobile } from 'react-device-detect'
import { ManageAdminResponse } from '@/utils/hooks/UseAdminDetail/types'

interface HeaderProps {
  setShowSidebar: Dispatch<SetStateAction<boolean>>
  showSidebar: boolean
  dataAdmin: ManageAdminResponse
  isLoading: boolean
}

const Header = ({
  setShowSidebar,
  showSidebar,
  dataAdmin,
  isLoading,
}: HeaderProps) => {
  const handleClickLogo = () => {
    if (isMobile) {
      setShowSidebar(!showSidebar)
    }
  }

  return (
    <HeaderWrapper>
      <HeaderLogo>
        <img src={logo} alt="logo" onClick={handleClickLogo} />
      </HeaderLogo>
      <HeaderAccount>
        <Text variant="body2">
          Halo,{' '}
          <Text color="lagoon">
            {!isLoading && dataAdmin ? dataAdmin.name : 'Admin'}
          </Text>
        </Text>
        <HeaderAccountIcon>
          <HiUsers />
        </HeaderAccountIcon>
      </HeaderAccount>
    </HeaderWrapper>
  )
}

export default Header
