import { styled } from '@/styled'

export const HeaderWrapper = styled('div', {
  position: 'fixed',
  left: 0,
  top: 0,
  height: '65px',
  width: '100%',
  backgroundColor: '$white',
  borderBottom: '1px solid #EFEFEF',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0em 1.5em',
  zIndex: 999,
})

export const HeaderLogo = styled('div', {
  '& img': {
    height: 48,
  },
})

export const HeaderAccount = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
})

export const HeaderAccountIcon = styled('div', {
  backgroundColor: '$smokeLine1',
  color: '$white',
  height: '32px',
  width: '32px',
  borderRadius: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
