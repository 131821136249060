import { FC } from 'react'

interface IconProps {
  size?: number
  color?: string
}

const IconDown: FC<IconProps> = ({ size = 24, color = '#202020' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.27034 2.07576L7.3592 9.22727C7.71965 9.59091 8.28035 9.59091 8.6408 9.22727L15.7297 2.07576C16.0901 1.71212 16.0901 1.14646 15.7297 0.782828C15.5695 0.621212 15.3292 0.5 15.0889 0.5C14.8486 0.5 14.6083 0.580808 14.4481 0.782828L8 7.28788L1.55194 0.782827C1.39174 0.621211 1.15144 0.499999 0.91114 0.499999C0.67084 0.499999 0.43054 0.580806 0.27034 0.782827C-0.09011 1.14646 -0.0901101 1.71212 0.27034 2.07576Z"
        fill={color}
      />
    </svg>
  )
}

export default IconDown
