import { ReactNode, createContext, useState } from 'react'
import Button from '../Button'
import { IconClose, IconSuccess } from '../Icons'
import Text from '../Text'

import Modal from './index'
import * as S from './styles'

export interface ConfigProps {
  id: string
  type: 'success' | 'danger' | 'custom'
  icon?: ReactNode
  onOk: () => void
  onOkText: string
  onCancelText: string | null
  onCancel: () => void
  title: string
  description: ReactNode | string
}
const initConfig: ConfigProps = {
  id: '',
  type: 'success',
  icon: <></>,
  onOk: () => {},
  onCancel: () => {},
  title: '',
  description: '',
  onOkText: 'Yes',
  onCancelText: 'No',
}

type ContextProps = {
  modal: boolean
  handleModal: (config: ConfigProps) => void
}

const ModalContext = createContext<ContextProps>({
  modal: false,
  handleModal: () => {},
})

const { Provider } = ModalContext

interface Props {
  children: ReactNode
}
const ModalProvider = ({ children }: Props) => {
  const [modal, setModal] = useState(false)

  const [configValue, setConfigValue] = useState(initConfig)

  const handleModal = (config: ConfigProps) => {
    setModal(!modal)

    if (config) {
      setConfigValue(config || configValue)
    }
  }

  return (
    <Provider value={{ modal, handleModal }}>
      <Modal id={configValue?.id} width={360} visible={modal}>
        <S.ModalConfirmWrapper>
          {configValue.type === 'success' && <IconSuccess />}
          {configValue.type === 'danger' && (
            <S.IconParentWrapper>
              <S.IconBackground />
              <S.IconWrapper>
                <IconClose size={40} color="#F04A41" />
              </S.IconWrapper>
            </S.IconParentWrapper>
          )}
          {configValue.type === 'custom' && (
            <S.IconParentWrapper>
              <S.IconBackground />
              <S.IconWrapper>{configValue.icon}</S.IconWrapper>
            </S.IconParentWrapper>
          )}
          <S.TextWrapper>
            {configValue?.title && (
              <Text
                css={{ textAlign: 'center' }}
                variant="h4"
                weight="bold"
                color="crow"
              >
                {configValue?.title}
              </Text>
            )}
            {configValue?.description && (
              <Text
                css={{ textAlign: 'center' }}
                color="smokeText"
                variant="body2"
              >
                {configValue?.description}
              </Text>
            )}
          </S.TextWrapper>
          <S.ButtonModalWrapper>
            {configValue?.onOkText && (
              <Button
                onClick={() => {
                  configValue?.onOk()
                  setModal(false)
                }}
                data-testid="modal-ok"
                id="modal-ok"
                size="medium"
                variant="primary"
                label={configValue?.onOkText}
                isFullWidth
              />
            )}
            {configValue?.onCancelText && (
              <Button
                onClick={() => {
                  configValue?.onCancel()
                  setModal(false)
                }}
                data-testid="modal-cancel"
                id="modal-cancel"
                size="medium"
                variant="tertiary"
                label={configValue?.onCancelText}
                isFullWidth
              />
            )}
          </S.ButtonModalWrapper>
        </S.ModalConfirmWrapper>
      </Modal>
      {children}
    </Provider>
  )
}

export { ModalContext, ModalProvider }
