import paths from '@/utils/constants/paths'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getAdminDetail } from './queries'
import { ManageAdminResponse } from './types'

const useAdminDetail = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [dataAdmin, setDataAdmin] = useState<ManageAdminResponse>()

  const { data, isLoading, refetch } = useQuery(
    ['FETCH_ADMIN_DETAIL_LOGIN'],
    async () => {
      const response = await getAdminDetail()

      return response
    },
    {
      enabled: !Boolean(location.pathname === paths.login),
    }
  )
  useEffect(() => {
    setLoading(isLoading)
    if (!isLoading) {
      setDataAdmin(data?.data ?? {})
    }
  }, [isLoading])

  return {
    dataAdmin,
    isLoading: loading,
    refetch,
  }
}

export default useAdminDetail
