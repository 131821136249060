import colors from '@/styled/colors'

interface Props {
  width?: number
  height?: number
  color?: string
  direction?: 'up' | 'down'
}

const IconTriangle = ({ width, height, color, direction }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 10}
    height={height || 8}
    viewBox="0 0 10 8"
    fill="none"
    style={{
      transform: direction === 'down' ? 'rotate(180deg)' : 'rotate(0deg)',
    }}
  >
    <path
      d="M5.40962 0.585166C5.21057 0.300808 4.78943 0.300807 4.59038 0.585166L0.300712 6.71327C0.0687404 7.04466 0.305816 7.5 0.710328 7.5H9.28967C9.69418 7.5 9.93126 7.04466 9.69929 6.71327L5.40962 0.585166Z"
      fill={color || colors.smoke}
    />
  </svg>
)

export default IconTriangle
