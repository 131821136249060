const fontSize = {
  body1: '16px',
  body2: '14px',
  body3: '12px',
  body4: '10px',
  h1: '64px',
  h2: '48px',
  h3: '36px',
  h4: '24px',
  h5: '18px',
}

export default fontSize
