/* eslint-disable func-names */
import { COOKIE_AUTHORIZATION } from '@/utils/constants/cookie'
import envVariable from '@/utils/constants/env'
import paths from '@/utils/constants/paths'
import axios from 'axios'
import Cookies from 'js-cookie'

const clientAxios = axios.create({
  baseURL: envVariable.URL_API,
})

const getCookieAuth = Cookies.get(COOKIE_AUTHORIZATION)

clientAxios.defaults.headers.common.Authorization = `Bearer ${getCookieAuth}`

// Add a response interceptor
clientAxios.interceptors.response.use(
  function (response) {
    // Do something with response data

    return response
  },
  function (error) {
    // Do something with response error

    if (error.response.status === 401) {
      Cookies.remove(COOKIE_AUTHORIZATION)
      window.location.href = paths.login
    }

    return Promise.reject(error)
  }
)

export default clientAxios
