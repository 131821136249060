import Text from '@/components/Text'
import { SidebarItem, SidebarItems, SidebarWrapper } from './styles'
import { FiLogOut } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import paths from '@/utils/constants/paths'
import menus from '@/utils/constants/menus'
import { COOKIE_AUTHORIZATION } from '@/utils/constants/cookie'
import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { ManageAdminResponse } from '@/utils/hooks/UseAdminDetail/types'
import { SUPERADMIN_LIST } from '@/utils/constants/general'

interface SidebarProps {
  showSidebar: boolean
  dataAdmin: ManageAdminResponse
  isLoading: boolean
}

const Sidebar = ({ showSidebar, dataAdmin, isLoading }: SidebarProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const handleLogout = () => {
    Cookies.remove(COOKIE_AUTHORIZATION)
    navigate(paths.login)
  }

  useEffect(() => {
    const getFirstPath = location.pathname.split('/')[1]
    if (!isLoading) {
      if (
        getFirstPath === 'admin' &&
        !SUPERADMIN_LIST.includes(dataAdmin.adminname)
      ) {
        navigate(paths.dashboard)
      }
    }
  }, [dataAdmin, location.pathname, isLoading])

  return (
    <>
      {showSidebar && (
        <SidebarWrapper>
          {dataAdmin && (
            <SidebarItems>
              {menus.map((menu) => {
                const Icon = menu.icon
                const currentPathName = location.pathname.split('/')
                const isActive = `/${currentPathName[1]}` === menu.path
                if (
                  menu.key !== 'admin' ||
                  (menu.key === 'admin' &&
                    SUPERADMIN_LIST.includes(dataAdmin.adminname))
                ) {
                  return (
                    <SidebarItem
                      role="link"
                      active={isActive}
                      key={`sidebar-${menu.key}`}
                      onClick={() => navigate(menu.path)}
                    >
                      <Icon />
                      <Text
                        variant="body2"
                        color={isActive ? 'white' : 'smokeText'}
                      >
                        {menu.label}
                      </Text>
                    </SidebarItem>
                  )
                }
              })}
              <SidebarItem
                role="button"
                onClick={handleLogout}
                key="sidebar-logout"
              >
                <FiLogOut />
                <Text variant="body2" color="smokeText">
                  Logout
                </Text>
              </SidebarItem>
            </SidebarItems>
          )}
        </SidebarWrapper>
      )}
    </>
  )
}

export default Sidebar
