import { COOKIE_WITHDRAWALMETHOD } from '@/utils/constants/cookie'
import { useQuery } from 'react-query'
import { getWithdrawalMethods } from './queries'
import paths from '@/utils/constants/paths'

const useGetWithdrawalMethod = () => {
  const getCookieWithdrawalMethod = localStorage.getItem(
    COOKIE_WITHDRAWALMETHOD
  )

  useQuery(
    ['FETCH_ALL_WITHDRAWAL_METHODS'],
    async () => {
      const response = await getWithdrawalMethods()

      return response
    },
    {
      enabled:
        !Boolean(getCookieWithdrawalMethod) &&
        !Boolean(location.pathname === paths.login),
      onSuccess: (data) => {
        const stringifyResponse = JSON.stringify(data.data)

        localStorage.setItem(COOKIE_WITHDRAWALMETHOD, stringifyResponse)

        window.location.reload()
      },
    }
  )

  const getBankByID = (uuid: string) => {
    const parseObjectWithdrawalMethods = JSON.parse(
      getCookieWithdrawalMethod as string
    )
    const getByUUID = parseObjectWithdrawalMethods?.find(
      (row: any) => row.uuid === uuid
    )

    return getByUUID
  }

  return {
    getBankByID,
    getCookieWithdrawalMethod,
  }
}

export default useGetWithdrawalMethod
