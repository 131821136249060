import { styled } from '@/styled'

export const ModalConfirmWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '36px',
  height: '100%',
  width: '100%',
  padding: '24px 0',
})

export const TextWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  padding: '0 1.5em',
})

export const ButtonModalWrapper = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: ' 0.75rem',
})

export const IconWrapper = styled('div', {
  backgroundColor: '$white',
  boxShadow: '0px 10px 20px rgba(255, 0, 0, 0.2)',
  width: '80px',
  height: '80px',
  borderRadius: '50%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const IconParentWrapper = styled('div', {
  position: 'relative',
  overflow: 'hidden',
  width: '120px',
  height: '120px',
  display: 'flex',
})

export const IconBackground = styled('div', {
  width: '120px',
  height: '120px',
  background: '$redPrimary',
  opacity: '0.3',
  borderRadius: '50%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

export const ModalFooter = styled('div', {
  borderTop: '1px solid $smokeLine2',
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '8px',
  alignItems: 'end',
  padding: '1.5em',
})

export const ModalRoot = styled('div', {
  position: 'fixed',
  left: '0',
  display: 'flex',
  right: '0',
  bottom: '0',
  top: '0',
  zIndex: '9998',
  background: 'rgba(32, 32, 32, 0.75)',
  variants: {
    enableOverflow: {
      true: {
        overflow: 'auto',
      },
    },
  },
})

export const ModalWrapper = styled('div', {
  background: '$white',
  margin: 'auto',
  borderRadius: '8px',
})

export const ModalTitleWrapper = styled('div', {
  padding: '24px 26px',
  fontSize: '$h4',
  fontWeight: '700',
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  variants: {
    titleAlign: {
      left: {
        textAlign: 'left',
      },
      center: {
        textAlign: 'center',
      },
      right: {
        textAlign: 'right',
      },
    },
    hideBorderTitle: {
      true: {
        borderBottom: 0,
      },
      false: {
        borderBottom: '1px solid $smokeLine2',
      },
    },
  },
})

export const ModalBody = styled('div', {
  padding: '24px 26px',
})

export const ModalCloseButtonWithTitle = styled('div', {
  marginTop: '-5px',
  cursor: 'pointer',
})

export const ModalCloseButtonWithoutTitle = styled('div', {
  position: 'absolute',
  right: '26px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: '24px',
  bottom: '0',
  cursor: 'pointer',
})
