import Loading from '@/components/Loading'
import { Fragment, Suspense, useEffect, useState } from 'react'
import { MainContent, MainWrapper } from './styles'
import { LayoutProps } from './types'
import { Helmet } from 'react-helmet'
import Sidebar from './components/Sidebar'
import Header from './components/Header'
import { isMobile } from 'react-device-detect'
import { ModalProvider } from '@/components/Modal/modalContext'
import Cookies from 'js-cookie'
import { COOKIE_AUTHORIZATION } from '@/utils/constants/cookie'
import { useLocation, useNavigate } from 'react-router-dom'
import paths from '@/utils/constants/paths'
import useAdminDetail from '@/utils/hooks/UseAdminDetail'
import dayjs from 'dayjs'
import useGetWithdrawalMethod from '@/utils/hooks/UseGetWithdrawalMethod'

import('dayjs/locale/id')

const Layout = ({ Container, title, useLayout }: LayoutProps) => {
  useGetWithdrawalMethod()

  dayjs.locale('id')

  const navigate = useNavigate()
  const location = useLocation()
  useEffect(() => {
    const getCookieAuth = Cookies.get(COOKIE_AUTHORIZATION)
    if (useLayout && !getCookieAuth) {
      navigate(paths.login)
    }
  }, [])

  const [showSidebar, setShowSidebar] = useState<boolean>(
    isMobile ? false : true
  )

  const { dataAdmin, isLoading } = useAdminDetail()

  useEffect(() => {
    if (isMobile) setShowSidebar(false)
  }, [location.pathname])

  return (
    <Fragment>
      <Helmet>
        <title>{`Pook Pow Dashboard - ${title}`}</title>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <Suspense fallback={<Loading />}>
        {useLayout ? (
          <ModalProvider>
            {dataAdmin && (
              <MainWrapper>
                <Sidebar
                  showSidebar={showSidebar}
                  dataAdmin={dataAdmin}
                  isLoading={isLoading}
                />
                <Header
                  setShowSidebar={setShowSidebar}
                  showSidebar={showSidebar}
                  dataAdmin={dataAdmin}
                  isLoading={isLoading}
                />
                <MainContent>
                  <Container />
                </MainContent>
              </MainWrapper>
            )}
          </ModalProvider>
        ) : (
          <Container />
        )}
      </Suspense>
    </Fragment>
  )
}

export default Layout
