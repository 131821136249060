const colors = {
  black: '#000000',
  white: '#FFFFFF',
  primary: '#7C05AC',
  primaryLight: '#A859CA',
  secondary: '#202020',
  redPrimary: '#F04A41',
  redPrimaryHover: '#c6423a',
  lagoon: '#00A2D8',
  lagoonLight: '#E5F6FB',
  lagoonDark: '#0688B3',
  parsley: '#00A050',
  parsleyLight: '#E7FFEC',
  parsleyDark: '#068646',
  yolk: '#FFDA2D',
  yolkLight: '#FFF4C0',
  yolkDark: '#A68E1D',
  crow: '#202020',
  crowHover: '#404040',
  smoke: '#707070',
  smokeText: '#ADADAD',
  smokeLine1: '#D8D8D8',
  smokeLine2: '#EFEFEF',
  smokeLight: '#FAFAFA',
  grayLight: '#E9EAED',
  lavender: '#BC7AD4',
  frenchLilac: '#DDBEEA',
}

export default colors
