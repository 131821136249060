import { globalCss } from '@stitches/react'

import colors from './colors'
import fontWeight from './fontWeight'

const globalStyles = globalCss({
  '@import':
    'url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap")',
  '*': {
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
  },
  html: {
    fontFamily: '"Source Sans Pro", sans-serif',
    fontWeight: fontWeight.regular,
    color: colors.crow,
  },
  a: {
    color: 'inherit',
    textDecoration: 'none',
  },
})

export default globalStyles
