import { Fragment, lazy } from 'react'

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import routes from '@/routes'
import Layout from '@/views/layouts'
import globalStyles from './styled/global'
import { QueryClientProvider } from 'react-query'

const Page404 = lazy(() => import('@/views/errors/Page404'))

import { AxiosError } from 'axios'
import { MutationCache, QueryCache, QueryClient } from 'react-query'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const App = () => {
  globalStyles()

  const handleError = (error: AxiosError) => {
    toast.error((error as any).response?.data?.message)
  }

  const client = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true
      },
    },
    queryCache: new QueryCache({
      onError: (error) => handleError(error as AxiosError),
    }),
    mutationCache: new MutationCache({
      onError: (error) => handleError(error as AxiosError),
    }),
  })

  return (
    <Fragment>
      <ToastContainer />
      <QueryClientProvider client={client}>
        <BrowserRouter>
          <Routes>
            {routes.map(({ path, container, title, useLayout }, i) => (
              <Route
                key={i}
                path={path}
                element={
                  <Layout
                    Container={container}
                    title={title}
                    useLayout={useLayout}
                  />
                }
              />
            ))}
            <Route
              path="*"
              element={
                <Layout
                  Container={Page404}
                  title={'Page Not Found'}
                  useLayout
                />
              }
            />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </Fragment>
  )
}
export default App
