interface Props {
  size?: number
  color?: string
}

const IconSortAscending = ({ size = 24, color = '#202020' }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.5559 12.9885H7.65043C6.82521 12.9885 6.10315 12.3702 6.10315 11.5458C6.10315 10.7214 6.82521 10 7.65043 10H40.5559C41.3811 10 42.1031 10.6183 42.1031 11.4427C42.1031 12.2672 41.3811 12.9885 40.5559 12.9885ZM7.54728 21.9541H21.8854C22.7106 21.9541 23.3295 22.6755 23.4327 23.4999C23.4327 24.3243 22.7106 25.0457 21.8854 25.0457H7.54728C6.72206 25.0457 6 24.3243 6 23.4999C6 22.6755 6.72206 21.9541 7.54728 21.9541ZM21.8854 34.0115H7.54728C6.72206 34.0115 6 34.7328 6 35.5573C6 36.3817 6.72206 37.1031 7.54728 37.1031H21.8854C22.7106 37.1031 23.4327 36.3817 23.4327 35.5573C23.3295 34.6298 22.7106 34.0115 21.8854 34.0115ZM37.0487 32.7747L39.4212 30.4045C40.0401 29.8892 40.9685 29.8892 41.5874 30.4045C42.2063 31.0228 42.2063 31.9503 41.5874 32.5686L38.2865 35.8663C37.5645 36.5877 36.533 36.9999 35.6046 36.9999C34.5731 36.9999 33.6447 36.5877 32.9226 35.8663L29.6218 32.5686C29.0029 31.9503 29.0029 31.0228 29.6218 30.4045C30.2407 29.7862 31.1691 29.7862 31.788 30.4045L33.9542 32.7747V23.4999C33.9542 22.6755 34.6762 21.9541 35.5014 21.9541C36.3266 21.9541 37.0487 22.6755 37.0487 23.4999V32.7747Z"
        fill={color}
      />
    </svg>
  )
}

export default IconSortAscending
