import type * as Stitches from '@stitches/react'

const utils = {
  mt: (value: Stitches.PropertyValue<'marginTop'>) => ({ marginTop: value }),
  mb: (value: Stitches.PropertyValue<'marginBottom'>) => ({
    marginBottom: value,
  }),
  ml: (value: Stitches.PropertyValue<'marginLeft'>) => ({ marginLeft: value }),
  mr: (value: Stitches.PropertyValue<'marginRight'>) => ({
    marginRight: value,
  }),
  mx: (value: string | number) => ({ marginLeft: value, marginRight: value }),
  my: (value: string | number) => ({ marginTop: value, marginBottom: value }),
  pt: (value: Stitches.PropertyValue<'paddingTop'>) => ({ paddingTop: value }),
  pb: (value: Stitches.PropertyValue<'paddingBottom'>) => ({
    paddingBottom: value,
  }),
  pl: (value: Stitches.PropertyValue<'paddingLeft'>) => ({
    paddingLeft: value,
  }),
  pr: (value: Stitches.PropertyValue<'paddingRight'>) => ({
    paddingRight: value,
  }),
  px: (value: Stitches.PropertyValue<'paddingLeft'>) => ({
    paddingLeft: value,
    paddingRight: value,
  }),
  py: (value: Stitches.PropertyValue<'paddingTop'>) => ({
    paddingTop: value,
    paddingBottom: value,
  }),
  p: (value: Stitches.PropertyValue<'padding'>) => ({ padding: value }),
  m: (value: Stitches.PropertyValue<'margin'>) => ({ margin: value }),
  d: (value: Stitches.PropertyValue<'display'>) => ({ display: value }),
}

export default utils
