import { styled } from '@/styled'
import { keyframes } from '@stitches/react'

const spin = keyframes({
  '0%': { transform: 'rotate(0turn)' },
  '100%': { transform: 'rotate(1turn)' },
})

export const StyledButton = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid',
  borderRadius: '6px',
  gap: 8,
  cursor: 'pointer',
  position: 'relative',
  userSelect: 'none',
  outline: 'none',

  '&:focus': {
    outline: 'none',
  },

  '&.loading': {
    overflow: 'hidden',

    '& *': {
      visibility: 'hidden',
    },

    '&::after': {
      content: '',
      position: 'absolute',
      width: '20px',
      height: '20px',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 'auto',
      border: '4px solid transparent',
      borderTopColor: '$white',
      borderRadius: '50%',
      animation: `${spin} 1s ease infinite`,
      userSelect: 'none',
    },
  },

  '&:disabled': {
    backgroundColor: '$smokeLine1',
    borderColor: '$smokeLine1',
    cursor: 'not-allowed',
    '& p': {
      color: '$smokeLine2',
    },
    '&:hover': {
      backgroundColor: '$smokeLine1',
      color: '$smokeLine2',
      borderColor: '$smokeLine1',
    },
  },

  variants: {
    size: {
      small: {
        height: '30px',
        padding: '0 12px',
      },
      medium: {
        height: '40px',
        padding: '0 16px',
      },
    },
    variant: {
      primary: {
        backgroundColor: '$primary',
        borderColor: '$primary',
        '&:hover': {
          backgroundColor: '$primaryLight',
        },
        '& p': {
          color: '$white',
        },

        '&:disabled': {
          '&.loading': {
            backgroundColor: '$primary',
            borderColor: '$primary',
          },
        },
      },
      primaryOutline: {
        background: 'transparent',
        borderColor: '$primary',
        '&:hover': {
          opacity: 0.7,
        },
        '& p': {
          color: '$primary',
        },
      },
      tertiary: {
        background: 'transparent',
        borderColor: '$smokeLine2',
        '&:hover': {
          backgroundColor: '$smokeLine1',
        },
        '&.loading': {
          backgroundColor: '$smokeLine2',
        },
      },
      danger: {
        background: '$redPrimary',
        borderColor: '$redPrimary',
        '&:hover': {
          opacity: 0.7,
        },
        '& p': {
          color: '$white',
        },
      },
      text: {
        background: 'transparent',
        borderColor: 'transparent',
        '&:hover': {
          backgroundColor: '$smokeLine1',
        },
      },
    },
    isFullWidth: {
      true: { width: '100%' },
      false: { width: 'auto' },
    },
    isActive: {
      true: {},
    },
  },
  compoundVariants: [
    {
      isActive: true,
      variant: 'tertiary',
      css: {
        backgroundColor: '$smokeLine1',
      },
    },
  ],
  defaultVariants: {
    size: 'medium',
    isFullWidth: false,
    isActive: false,
  },
})
