interface Props {
  size?: number
  color?: string
}

const IconSortDescending = ({ size = 24, color = '#202020' }: Props) => {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.5559 12.9885H7.65043C6.82521 12.9885 6.10315 12.3702 6.10315 11.5458C6.10315 10.7214 6.82521 10 7.65043 10H40.5559C41.3811 10 42.1031 10.6183 42.1031 11.4427C42.1031 12.2672 41.3811 12.9885 40.5559 12.9885ZM7.54728 21.9541H21.8854C22.7106 21.9541 23.3295 22.6755 23.4327 23.4999C23.4327 24.3243 22.7106 25.0457 21.8854 25.0457H7.54728C6.72206 25.0457 6 24.3243 6 23.4999C6 22.6755 6.72206 21.9541 7.54728 21.9541ZM21.8854 34.0115H7.54728C6.72206 34.0115 6 34.7328 6 35.5573C6 36.3817 6.72206 37.1031 7.54728 37.1031H21.8854C22.7106 37.1031 23.4327 36.3817 23.4327 35.5573C23.3295 34.6298 22.7106 34.0115 21.8854 34.0115ZM34.1605 26.1793L31.788 28.5495C31.1691 29.0648 30.2407 29.0648 29.6218 28.5495C29.0029 27.9312 29.0029 27.0037 29.6218 26.3854L32.9226 23.0877C33.6447 22.3663 34.6762 21.9541 35.6046 21.9541C36.6361 21.9541 37.5645 22.3663 38.2865 23.0877L41.5874 26.3854C42.2063 27.0037 42.2063 27.9312 41.5874 28.5495C40.9685 29.1678 40.0401 29.1678 39.4212 28.5495L37.255 26.1793V35.4541C37.255 36.2785 36.5329 36.9999 35.7077 36.9999C34.8825 36.9999 34.1605 36.2785 34.1605 35.4541L34.1605 26.1793Z"
        fill={color}
      />
    </svg>
  )
}

export default IconSortDescending
