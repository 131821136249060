import { FC, ReactNode } from 'react'

import { CSS, VariantProps } from '@stitches/react'
import { styled, theme } from '@/styled'
import { ColorKeys } from '@/styled/stitches.config'

const BaseText = styled('span', {
  color: '$crow',
  wordBreak: 'keep-all',

  variants: {
    variant: {
      h1: {
        fontSize: '$h1',
      },
      h2: {
        fontSize: '$h2',
      },
      h3: {
        fontSize: '$h3',
      },
      h4: {
        fontSize: '$h4',
      },
      h5: {
        fontSize: '$h5',
      },
      body1: {
        fontSize: '$body1',
      },
      body2: {
        fontSize: '$body2',
      },
      body3: {
        fontSize: '$body3',
      },
      body4: {
        fontSize: '$body4',
      },
    },
    weight: {
      regular: {
        fontWeight: '$regular',
      },
      semibold: {
        fontWeight: '$semibold',
      },
      bold: {
        fontWeight: '$bold',
      },
    },
    hasClamp: {
      true: {
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
      },
      false: {},
    },
    lineClamp: {
      1: {
        '-webkit-line-clamp': 1,
      },
      2: {
        '-webkit-line-clamp': 2,
      },
      3: {
        '-webkit-line-clamp': 3,
      },
    },
    textAlign: {
      center: {
        textAlign: 'center',
      },
      left: {
        textAlign: 'left',
      },
      right: {
        textAlign: 'right',
      },
    },
    color: Object.entries(theme.colors).reduce(
      (acc, color) => ({
        ...acc,
        [color[0]]: {
          color: color[1].value,
        },
      }),
      {}
    ) as Record<ColorKeys, CSS>,
  },

  defaultVariants: {
    variant: 'body1',
    weight: 'regular',
    color: 'crow',
  },
})

type BaseTextProps = VariantProps<typeof BaseText>

interface BaseProps {
  id?: string
  className?: string
}

export type TextProps = BaseProps &
  Omit<BaseTextProps, 'color'> & {
    paragraph?: boolean
    children?: ReactNode
    color?: ColorKeys
    lineClamp?: number
    onClick?: () => void
    css?: CSS
  }

const Text: FC<TextProps> = ({
  variant,
  weight,
  children,
  id,
  paragraph,
  color,
  lineClamp,
  onClick,
  ...rest
}: TextProps) => {
  return (
    <BaseText
      suppressHydrationWarning
      id={id}
      data-testid={id}
      variant={variant}
      weight={weight}
      color={color}
      as={
        paragraph
          ? 'p'
          : variant === 'h1' ||
            variant === 'h2' ||
            variant === 'h3' ||
            variant === 'h4' ||
            variant === 'h5'
          ? variant
          : 'span'
      }
      hasClamp={Boolean(lineClamp)}
      lineClamp={lineClamp}
      onClick={onClick}
      {...rest}
    >
      {children}
    </BaseText>
  )
}

export default Text
